<template>
  <div class="new-video fade-up">
    <div v-if="isError">Топилмади!</div>
    <el-row v-else :gutter="30" style="position: relative;">
      <el-col :span="16">
        <el-card v-loading="langLoading" class="video-content-card">
          <div class="card-header" slot="header">
            <div class="lang-section">
              <h3>Тилни танланг:</h3>
              <el-radio-group v-model="selectedLang">
                <el-radio-button label="oz">O'zbekcha</el-radio-button>
                <el-radio-button label="uz">Узбекча</el-radio-button>
                <el-radio-button label="ru">Русский</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div
            class="title-section"
            :class="{
              'invalid-error': $v.form[`title_${selectedLang}`].$error
            }"
          >
            <h3>Номи</h3>
            <el-input
              size="large"
              placeholder="Номини киритинг"
              v-model="$v.form[`title_${selectedLang}`].$model"
            ></el-input>
          </div>
          <div class="title-section">
            <h3>Видео</h3>
            <file-input
              v-if="!langLoading"
              accept="video/mp4,video/x-m4v,video/*"
              v-model="form[`video_${selectedLang}`]"
            />
          </div>
          <div class="content-section">
            <h3>Контент</h3>
            <quill-editor
              class="editor"
              v-model="form[`content_${selectedLang}`]"
            />
          </div>
        </el-card>
      </el-col>
      <el-col :span="8" style="position: sticky; top: 0">
        <el-card>
          <div
            class="date-section"
            :class="{
              'invalid-error': $v.form.publish_at.$error
            }"
          >
            <h4>Нашр этиш вақти</h4>
            <el-date-picker
              v-model="$v.form.publish_at.$model"
              type="datetime"
              placeholder="Санани танланг"
              class="date-input"
            >
            </el-date-picker>
          </div>
          <div
            class="photo-upload-section"
            :class="{
              'invalid-error': $v.thumbnail.$error
            }"
          >
            <h4>Расм</h4>
            <drop-file-input v-model="$v.thumbnail.$model" />
          </div>
          <div :style="{ display: 'flex' }">
            <el-button
              @click="saveVideo"
              type="success"
              icon="el-icon-check"
              :disabled="isLoading"
              :style="{ flex: 1 }"
            >
              Сақлаш
            </el-button>
            <el-button
              @click="deleteVideo"
              :disabled="isLoading"
              type="danger"
              icon="el-icon-delete"
              :style="{ flex: 1 }"
            >
              Ўчириш
            </el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DropFileInput from "@/components/DropFileInput";
import FileInput from "@/components/FileInput";
import { required } from "vuelidate/lib/validators";
import config from "@/config";

export default {
  components: {
    DropFileInput,
    FileInput
  },
  data: () => ({
    isError: false,
    isLoading: false,
    form: {
      title_uz: "",
      title_ru: "",
      title_oz: "",
      video_uz: null,
      video_ru: null,
      video_oz: null,
      content_uz: "",
      content_ru: "",
      content_oz: "",
      publish_at: "",
      status: "draft"
    },
    selectedLang: "oz",
    thumbnail: null,
    langLoading: false
  }),
  validations: {
    form: {
      title_uz: { required },
      title_ru: { required },
      title_oz: { required },
      publish_at: { required }
    },
    thumbnail: { required }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    this.$store
      .dispatch("fetchSingleVideo", this.$route.params.id)
      .then(singleVideo => {
        this.form = { ...singleVideo };
        this.form.video_uz = singleVideo.video_uz
          ? { name: singleVideo.video_uz.name }
          : null;
        this.form.video_ru = singleVideo.video_ru
          ? { name: singleVideo.video_ru.name }
          : null;
        this.form.video_oz = singleVideo.video_oz
          ? { name: singleVideo.video_oz.name }
          : null;
        this.thumbnail = config.baseUrl + singleVideo.thumbnail.url;
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        loading.close();
      });
  },
  watch: {
    selectedLang() {
      this.langLoading = true;
      setTimeout(() => {
        this.langLoading = false;
      }, 500);
    }
  },
  methods: {
    saveVideo() {
      if (this.isLoading) return;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify.error({
          title: "Диққат!",
          message: "Формани тўлдиринг!"
        });

        return false;
      } else {
        this.isLoading = true;
        const loading = this.$loading({
          lock: true
        });
        const data = { ...this.form };
        delete data.video_uz;
        delete data.video_ru;
        delete data.video_oz;
        const formData = new FormData();
        if (+new Date(this.form.publish_at) <= +new Date()) {
          data.status = "published";
        }
        formData.append("data", JSON.stringify(data));
        if (typeof this.thumbnail !== "string" && this.thumbnail !== null) {
          formData.append("files.thumbnail", this.thumbnail);
        }
        if (this.form.video_uz instanceof File) {
          formData.append("files.video_uz", this.form.video_uz);
        }
        if (this.form.video_ru instanceof File) {
          formData.append("files.video_ru", this.form.video_ru);
        }
        if (this.form.video_oz instanceof File) {
          formData.append("files.video_oz", this.form.video_oz);
        }
        this.$store
          .dispatch("updateVideo", {
            data: formData,
            id: this.$route.params.id
          })
          .then(() => {
            this.$message({
              message: "Видео сақланди!",
              type: "success"
            });
            this.$router.push("/videos");
          })
          .finally(() => {
            this.isLoading = false;
            loading.close();
          });
      }
    },
    deleteVideo() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          const loading = this.$loading({
            lock: true
          });
          this.$store
            .dispatch("deleteVideo", this.$route.params.id)
            .then(() => {
              this.$router.push("/videos");
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => {
              this.isLoading = false;
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title-section,
.content-section,
.desc-section,
.hashtag-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
}

.video-content-card {
  position: relative;
  .el-card__header {
    position: sticky;
    top: 0;
  }
}

.lang-section h3 {
  margin-bottom: 10px;
}

.new-video {
  padding: 20px;
}

// .editor {
//   height: 400px;
//   display: flex;
//   flex-direction: column;
// }

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.category-section,
.photo-upload-section,
.date-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h4 {
    margin-bottom: 10px;
  }
}

.desc-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-input {
  width: 100%;
}
</style>
