<template>
  <div
    class="drop-photo-input drop-photo-input-dropzone"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <label class="drop-photo-input__label">
      <div v-if="!file" class="drop-photo-input__title">
        <i class="el-icon-upload upload-icon"></i>
        Расмни шу ерга ташланг
      </div>
      <div v-else class="drop-photo-input__photo">
        <img :src="imageData" class="img-responsive" alt="photo" />
      </div>
      <input
        ref="file"
        type="file"
        accept="image/jpeg,image/png,image/gif"
        @change="onChange($event)"
      />
    </label>
  </div>
</template>

<script>
export default {
  data: () => ({
    file: null,
    imageData: ""
  }),
  // eslint-disable-next-line
  props: ['initPhoto', 'value'],
  created() {
    if (this.initPhoto) {
      this.imageData = this.initPhoto;
      this.file = this.initPhoto;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val !== null && typeof val !== "string") {
          this.file = val;
        }
        if (typeof val === "string") {
          this.imageData = val;
          this.file = val;
        }

        if (val === null) {
          this.imageData = "";
          this.file = null;
        }
      }
    },
    file: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val !== null && typeof val !== "string") {
          const reader = new FileReader();
          reader.onload = e => {
            this.imageData = e.target.result;
            this.chosen = true;
          };
          if (this.file) {
            reader.readAsDataURL(this.file);
          }
          this.$emit("input", this.file);
        }
      }
    }
  },
  methods: {
    onChange() {
      this.file = this.$refs.file.files[0];
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("dropzone--active")) {
        event.currentTarget.classList.remove("dropzone--disabled");
        event.currentTarget.classList.add("dropzone--active");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("dropzone--disabled");
      event.currentTarget.classList.remove("dropzone--active");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("dropzone--disabled");
      event.currentTarget.classList.remove("dropzone--active");
    }
  }
};
</script>

<style lang="scss" scoped>
.drop-photo-input {
  transition: 0.2s all;
  border: 1px dashed #dbdbdb;
  border-radius: 6px;
  display: flex;
  align-items: stretch;

  .upload-icon {
    font-size: 40px;
  }

  &.dropzone--active {
    border-color: #41bd86;
  }

  &__label {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center;
    width: 100%;
  }

  &__photo {
    padding: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 4px solid #dbdbdb;
    width: 100%;
    max-height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    padding: 50px 0;
    color: #a2b2d1;
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      width: 50px;
      height: 38px;
      fill: #eff5fe;
      margin-bottom: 30px;
    }

    span {
      color: #486bf6;
      text-decoration: underline;
    }
  }

  &__button {
    background: #8a939a;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    color: #ffffff;
    font-size: 12px;
    line-height: 1.5;
    transition: 0.2s all;

    &:hover,
    &:focus {
      cursor: pointer;
      background: darken(#8a939a, 10);
    }
  }

  input[type="file"] {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}
</style>
