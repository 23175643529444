<template>
  <label class="input-file">
    <div class="input-file__btn">
      <i class="el-icon-plus"></i>
      <span>Файл юклаш</span>
    </div>
    <span class="input-file__name">
      {{ file ? file.name : "Файл танланмаган" }}
    </span>
    <input
      type="file"
      @input="handleChange"
      class="visually-hidden"
      ref="file"
      :accept="accept"
    />
  </label>
</template>

<script>
export default {
  props: ["value", "accept"],
  data: () => ({
    file: null
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.file = val;
      }
    }
  },
  methods: {
    handleChange() {
      this.file = this.$refs.file.files[0];
      this.$emit("input", this.file);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-file {
  display: flex;
  align-items: center;
  &__name {
    font-style: italic;
    margin-left: 15px;
    font-size: 0.875rem;
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    background: #f0f0f3;
    border-radius: 2px;
    padding: 10px 15px;
    transition: all 0.2s;
    i {
      margin-right: 10px;
    }
    &:hover,
    &:focus {
      background: darken(#f0f0f3, 5);
      cursor: pointer;
    }
    &:active {
      background: darken(#f0f0f3, 10);
    }
  }
}
</style>
